export const MESSAGE_BASE_FRAGMENT = `
  fragment messageBaseFragment on Message {
    uid
    schemaCode
    content
    userDeleted
    senttime
    senttimestamp
  }
`;
